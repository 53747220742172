var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"contacts-form",class:_vm.className},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.isFormSent)?_c('div',{key:"1",staticClass:"contacts-form-result"},[_c('p',[_vm._v(" Здравствуйте! Мы получили ваше письмо и свяжемся с вами в течение суток. Спасибо, что доверяете мобильному приложению GO AND GUIDE 360! ")]),_c('p',[_vm._v("Команда GO AND GUIDE 360")])]):_c('div',{key:"2",staticClass:"contacts-form__fields"},[_c('div',{staticClass:"form-group",class:{
          invalid: _vm.$v.name.$dirty && _vm.$v.name.$invalid,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name),expression:"name",modifiers:{"trim":true}}],staticClass:"form-input",attrs:{"type":"text","placeholder":"Ваше имя"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"form-error"},[_vm._v("Поле необходимо заполнить")])]),_c('div',{staticClass:"form-group",class:{
          invalid: _vm.$v.phone.$dirty && _vm.$v.phone.$invalid,
        }},[_c('masked-input',{staticClass:"form-input",attrs:{"mask":"\\+\\7 (111) 111-11-11","placeholder":"Номер телефона"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('div',{staticClass:"form-error"},[_vm._v("Введите номер телефона")])],1),_c('div',{staticClass:"form-group",class:{
          invalid: _vm.$v.email.$dirty && _vm.$v.email.$invalid,
        }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"form-input",attrs:{"type":"text","placeholder":"Email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('div',{staticClass:"form-error"},[_vm._v("Введите email")])]),_c('div',{staticClass:"form-group",class:{
          invalid: _vm.$v.agree.$dirty && _vm.$v.agree.$invalid,
        }},[_c('label',{staticClass:"checkbox"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.agree),expression:"agree"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.agree)?_vm._i(_vm.agree,null)>-1:(_vm.agree)},on:{"change":function($event){var $$a=_vm.agree,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.agree=$$a.concat([$$v]))}else{$$i>-1&&(_vm.agree=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.agree=$$c}}}}),_c('span',{staticClass:"checkbox__checkmark"}),_vm._v(" Нажимая кнопку “Отправить” вы соглашаетесь с "),_c('router-link',{attrs:{"to":{ name: 'PrivacyPolicy' },"target":"_blank"}},[_vm._v(" “Политикой конфиденциальности” ")]),_vm._v(" . ")],1),_c('div',{staticClass:"form-error"},[_vm._v("Поле необходимо заполнить")])]),(_vm.errors.sendFeedback.error)?_c('div',{staticClass:"form-group invalid"},[_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(_vm.errors.sendFeedback.error))])]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('button',{staticClass:"btn contacts-form__btn",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_vm._v(" Отправить ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }