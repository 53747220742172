<template>
  <form class="contacts-form" :class="className">
    <transition name="fade" mode="out-in">
      <div v-if="isFormSent" key="1" class="contacts-form-result">
        <p>
          Здравствуйте! Мы получили ваше письмо и свяжемся с вами в течение
          суток. Спасибо, что доверяете мобильному приложению GO AND GUIDE 360!
        </p>
        <p>Команда GO AND GUIDE 360</p>
      </div>

      <div v-else key="2" class="contacts-form__fields">
        <div
          class="form-group"
          :class="{
            invalid: $v.name.$dirty && $v.name.$invalid,
          }"
        >
          <input
            type="text"
            class="form-input"
            placeholder="Ваше имя"
            v-model.trim="name"
          />
          <div class="form-error">Поле необходимо заполнить</div>
        </div>

        <div
          class="form-group"
          :class="{
            invalid: $v.phone.$dirty && $v.phone.$invalid,
          }"
        >
          <masked-input
            v-model="phone"
            mask="\+\7 (111) 111-11-11"
            placeholder="Номер телефона"
            class="form-input"
          />

          <div class="form-error">Введите номер телефона</div>
        </div>

        <div
          class="form-group"
          :class="{
            invalid: $v.email.$dirty && $v.email.$invalid,
          }"
        >
          <input
            type="text"
            class="form-input"
            placeholder="Email"
            v-model.trim="email"
          />
          <div class="form-error">Введите email</div>
        </div>

        <div
          class="form-group"
          :class="{
            invalid: $v.agree.$dirty && $v.agree.$invalid,
          }"
        >
          <label class="checkbox">
            <input type="checkbox" v-model="agree" />
            <span class="checkbox__checkmark"></span>
            Нажимая кнопку “Отправить” вы соглашаетесь с
            <router-link :to="{ name: 'PrivacyPolicy' }" target="_blank">
              “Политикой конфиденциальности”
            </router-link>
            .
          </label>
          <div class="form-error">Поле необходимо заполнить</div>
        </div>

        <div v-if="errors.sendFeedback.error" class="form-group invalid">
          <div class="form-error">{{ errors.sendFeedback.error }}</div>
        </div>

        <div class="form-group">
          <button
            type="button"
            class="btn contacts-form__btn"
            @click.prevent="onSubmit"
            :disabled="loading"
          >
            Отправить
          </button>
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import MaskedInput from "vue-masked-input";

export default {
  name: "ContactsForm",
  props: {
    className: String,
    sourcePage: {
      type: String,
      required: true,
    },
  },
  components: { MaskedInput },
  data() {
    return {
      isFormSent: false,
      loading: false,
      agree: false,
      name: "",
      email: "",
      phone: "",
    };
  },
  validations: {
    name: { required },
    email: { required, email },
    phone: {
      required,
      fullVal: (value) => {
        return !value.includes("_");
      },
    },
    agree: {
      checked: (value) => value === true,
    },
  },
  computed: {
    ...mapState("feedback", ["errors"]),
  },
  methods: {
    ...mapActions("feedback", ["sendFeedback", "resetSendFeedback"]),
    async onSubmit() {
      if (this.loading) return;

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;

        let data = {
          name: this.name,
          phonenumber: this.phone,
          email: this.email,
          source_page: this.sourcePage,
        };

        await this.sendFeedback(data);

        this.loading = false;

        if (!Object.keys(this.errors.sendFeedback).length) {
          this.isFormSent = true;
          this.$v.$reset();

          this.name = "";
          this.phone = "";
          this.email = "";
          this.agree = false;
        }
      }
    },
  },
  beforeDestroy() {
    this.resetSendFeedback();
  },
};
</script>
